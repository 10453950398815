import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import Seo from '../../../components/Seo'
import {
  seo,
  intro,
  main,
  about,
  blog,
  upcoming,
  achievements,
} from '../../../content/aleksandra-wasiewicz'
import { useLangContext } from '../../../context/lang.context'
import Intro from '../../../components/Intro'
import Main from '../../../components/Main'
import Video from '../../../components/Video'
import ImageSection from '../../../components/ImageSection'
import { colors, sDesc } from '../../../style'
import YouTubeIcon from '../../../assets/icons/youtubeIcon'
import InstagramIcon from '../../../assets/icons/instagramIcon'
import FacebookIcon from '../../../assets/icons/facebookIcon'
import Events from '../../../components/Events'

const video = 'https://www.youtube.com/embed/F0nzZiAkbnE'

const videos = [
  'https://www.youtube.com/embed/F0nzZiAkbnE',
  'https://www.youtube.com/embed/fqFHBPTR7dc',
  'https://www.youtube.com/embed/cWQBf9tK7Rk',
  'https://www.youtube.com/embed/bw1eio2k_Pc',
  'https://www.youtube.com/embed/8hpzS8kNXos',
]

const OlaWasiewicz = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(
        absolutePath: { regex: "/images/intros/aleksandra-wasiewicz.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      imageAbout: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/wasiewicz.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1
          )
        }
      }
      image1: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image1.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image2: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image2.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image3: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image3.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image4: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image4.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image5: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image5.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image6: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image6.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image7: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image7.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image8: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image8.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image9: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image9.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image10: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image10.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image11: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image11.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image12: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image12.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image13: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image13.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image14: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image14.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image15: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image15.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image16: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image16.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image17: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/image17.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Przeglądaj',
      en: 'Explore',
    },
    action: 'SCROLL',
  }

  const eventsAchivements = achievements.events
    .map((e, id) => ({ ...e, image: query[`image${id + 1}`]?.childImageSharp }))
    .reverse()

  const eventsUpcoming = upcoming.events

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button }}
        image={query.image.childImageSharp}
        position='bottom'
      />
      <Main h={2} s={1} title={main.title[lang]} body={main.body[lang]}>
        <div css={{ paddingBottom: 80 }} />
        <Video video={video} videos={videos} />
      </Main>
      <Main h={1} title={about.title[lang]} desc={about.desc[lang]}>
        {about.sections?.map(({ title, desc, social }, id) => (
          <ImageSection
            // html
            noeffect
            h={2}
            s={2}
            key={id}
            title={title[lang]}
            desc={desc?.[lang]}
            image={query.imageAbout.childImageSharp}
          >
            <p css={sSocialText}>{social[lang]}</p>
            <div css={sSocialIcons}>
              <a
                href='https://www.youtube.com/channel/UCVN-h5l2ByxEjGFxqUbCV4A'
                target='_blank'
                rel='noreferrer noopener'
              >
                <YouTubeIcon />
              </a>
              <a
                href='https://www.facebook.com/aleksandrawasiewiczpol122'
                target='_blank'
                rel='noreferrer noopener'
              >
                <FacebookIcon />
              </a>
              <a
                href='https://www.instagram.com/a.wasiewicz/'
                target='_blank'
                rel='noreferrer noopener'
              >
                <InstagramIcon />
              </a>
            </div>
          </ImageSection>
        ))}
      </Main>
      <Main h={3} s={2} title={achievements.title[lang]}>
        <Events data={eventsAchivements} />
      </Main>
      <Main h={3} s={2} title={upcoming.title[lang]}>
        <Events data={eventsUpcoming} />
      </Main>
    </Layout>
  )
}

const sSocialText = {
  margin: '2rem 0 2rem',
  ...sDesc,
}

const sSocialIcons = {
  display: 'flex',
  color: colors.brandDark,

  svg: {
    width: '2rem',
    height: '2rem',
    marginRight: '1rem',
    transition: 'color 0.2s ease-out',
    '&:hover': {
      color: colors.brandDarker,
    },
  },
}

export default OlaWasiewicz
